<script setup lang="ts">
/**
 * @file Background-less button.
 */
import OzBaseButton, { OzBaseButtonHrefMode } from '@@/library/v4/components/OzBaseButton.vue'

withDefaults(
  defineProps<{
    darkMode?: boolean | 'auto'
    disabled?: boolean
    /**
     * Automatically uses an `<a>` tag if href is present, `<button>` otherwise.
     */
    href?: string
    hrefTarget?: string
    hrefMode?: OzBaseButtonHrefMode
    /**
     * Determines the color of the link. Use `Active` when you want to force the selection color.
     */
    colorScheme?: OzPlainButtonColorScheme
    /**
     * Applies preset sizes/shapes.
     * They are named with pixel values for developer ergonomics -- those are the values you see in the mockups.
     * Technically, the pixel value could vary depending on the size of `rem`.
     */
    sizePreset?: OzPlainButtonSizePreset
    /**
     * Defines whether the button text should be truncated or not.
     * Use case:
     *
     * 1. `true` - truncate the button text if it exceeds the button's width
     * 2. `false` - do not truncate the button text, allowing it to stretch the button's height
     */
    shouldTruncateText?: boolean
    /**
     * Defines whether the button's height should be fixed or adjusted by its content.
     */
    autoHeight?: boolean
    text?: string
    title?: string
    iconLeft?: boolean
    /**
     * Should be set to false if we are showing a custom tooltip at the button instead of the native HTML tooltip for displaying the title.
     * */
    xTitle?: boolean

    /**
     * Should be set to false if we are showing a text button that is not bold.
     * */
    boldText?: boolean
  }>(),
  {
    darkMode: 'auto',
    disabled: false,
    href: undefined,
    hrefTarget: undefined,
    hrefMode: OzBaseButtonHrefMode.Default,
    colorScheme: OzPlainButtonColorScheme.Primary,
    sizePreset: OzPlainButtonSizePreset.H44px,
    shouldTruncateText: true,
    autoHeight: false,
    text: undefined,
    title: undefined,
    iconLeft: false,
    xTitle: true,
    boldText: true,
  },
)

defineEmits<{
  (name: 'mouseenter', event: MouseEvent): void
  (name: 'mouseleave', event: MouseEvent): void
  (name: 'mousedown', event: MouseEvent): void
  (name: 'keydown', event: KeyboardEvent): void
  (name: 'click', event: PointerEvent): void
  (name: 'focus', event: FocusEvent): void
  (name: 'blur', event: FocusEvent): void
  (name: 'touchstart', event: TouchEvent): void
  (name: 'pointerdown', event: PointerEvent): void
}>()
</script>

<script lang="ts">
export enum OzPlainButtonColorScheme {
  /** Bare does not add any background color or text color classes. */
  Bare = 'Bare',
  Primary = 'Primary',
  Secondary = 'Secondary',
  SecondaryIcon = 'SecondaryIcon',
  TertiaryIcon = 'TertiaryIcon',
  Active = 'Active',
  SecondaryIconInverse = 'SecondaryIconInverse',
}

export enum OzPlainButtonSizePreset {
  /** Bare doesn't add height, text size, padding, border radius, or focus ring classes. */
  Bare = 'Bare',
  H44px = 'H44px',
  H40px = 'H40px',
  H32px = 'H32px',
  H28px = 'H28px',
  H24px = 'H24px',
  H18px = 'H18px',
}

export { OzBaseButtonHrefMode as OzPlainButtonHrefMode } from '@@/library/v4/components/OzBaseButton.vue'
export default {}
</script>

<template>
  <OzBaseButton
    :disabled="disabled"
    :href="href"
    :target="hrefTarget"
    :href-mode="hrefMode"
    :title="!xTitle ? undefined : title || text"
    :class="[
      // Resets <button> background.
      // Invariant: OzPlainButton doesn't have a background.
      // If you need a background, use OzContainedButton.
      'bg-transparent',

      boldText && 'font-semibold',
      sizePreset && [
        // Remove the default focus ring since we're rounding the corners
        'focus-visible:outline-none',

        // Reset user agent styles
        // Don't set px-0 here so we won't have specificity issues
        'py-0',

        // Centralize button content
        'flex',
        'items-center',
        'justify-center',
      ],

      // Size presets
      sizePreset === OzPlainButtonSizePreset.H44px && [
        autoHeight ? 'min-h-11' : 'h-11',
        'text-heading-4',
        'px-2',
        'rounded-2xl',
        'focus-visible:ring-[2.5px]',
      ],
      sizePreset === OzPlainButtonSizePreset.H40px && [
        autoHeight ? 'min-h-10' : 'h-10',
        'text-heading-4',
        'px-2',
        'rounded-2xl',
        'focus-visible:ring-[2.5px]',
      ],
      sizePreset === OzPlainButtonSizePreset.H32px && [
        autoHeight ? 'min-h-8' : 'h-8',
        'text-body-small',
        'px-2',
        'rounded-xl',
        'focus-visible:ring-2',
      ],
      sizePreset === OzPlainButtonSizePreset.H28px && [
        autoHeight ? 'min-h-7' : 'h-7',
        'text-body-small',
        'px-1',
        'rounded-[10px]',
        'focus-visible:ring-2',
        $slots.icon && 'w-7',
      ],
      sizePreset === OzPlainButtonSizePreset.H24px && [
        autoHeight ? 'min-h-6' : 'h-6',
        'text-body-extra-small',
        'rounded-lg',
        'focus-visible:ring-2',
      ],
      sizePreset === OzPlainButtonSizePreset.H18px && [
        autoHeight ? 'min-h-4.5' : 'h-4.5',
        $slots.icon && 'w-4.5',
        'text-body-extra-small',
        'px-0',
        'rounded',
        'focus-visible:ring-[2.5px]',
      ],

      colorScheme && [
        // Disabled - Base
        {
          'aria-disabled:text-dark-text-400': darkMode === false,
          'aria-disabled:text-light-text-400': darkMode === true,
          'aria-disabled:text-dark-text-400 dark:aria-disabled:text-light-text-400': darkMode === 'auto',
        },
        // Disabled - Hover
        {
          'hover-hover:aria-disabled:text-dark-text-400': darkMode === false,
          'hover-hover:aria-disabled:text-light-text-400': darkMode === true,
          'hover-hover:aria-disabled:text-dark-text-400 hover-hover:dark:aria-disabled:text-light-text-400':
            darkMode === 'auto',
        },
      ],

      // Color schemes
      colorScheme === OzPlainButtonColorScheme.Primary && [
        // Base
        'text-padlet-pink',
        // Hovered
        {
          'hover-hover:hover:text-padlet-pink-700': darkMode === false,
          'hover-hover:hover:text-padlet-pink-600': darkMode === true,
          'hover-hover:hover:text-padlet-pink-700 hover-hover:dark:hover:text-padlet-pink-600': darkMode === 'auto',
        },
        // Active
        {
          'hover-hover:active:text-padlet-pink-100': darkMode === false,
          'hover-hover:active:text-padlet-pink-900': darkMode === true,
          'hover-hover:active:text-padlet-pink-100 hover-hover:dark:active:text-padlet-pink-900': darkMode === 'auto',
        },
      ],
      colorScheme === OzPlainButtonColorScheme.Secondary && [
        // Base
        {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:text-grey-600': darkMode === false,
          'hover-hover:hover:text-grey-200': darkMode === true,
          'hover-hover:hover:text-grey-600 hover-hover:dark:hover:text-grey-200': darkMode === 'auto',
        },
        // Active
        {
          'hover-hover:active:text-dark-text-400': darkMode === false,
          'hover-hover:active:text-light-text-400': darkMode === true,
          'hover-hover:active:text-dark-text-400 hover-hover:dark:active:text-light-text-400': darkMode === 'auto',
        },
      ],
      colorScheme === OzPlainButtonColorScheme.SecondaryIcon && [
        // Base
        {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:text-dark-text-100': darkMode === false,
          'hover-hover:hover:text-light-text-100': darkMode === true,
          'hover-hover:hover:text-dark-text-100 hover-hover:dark:hover:text-light-text-100': darkMode === 'auto',
        },
        // Active
        {
          'hover-hover:active:text-grape-500': darkMode === false,
          'hover-hover:active:text-canary-500': darkMode === true,
          'hover-hover:active:text-grape-500 hover-hover:dark:active:text-canary-500': darkMode === 'auto',
        },
      ],
      colorScheme === OzPlainButtonColorScheme.SecondaryIconInverse && [
        // Base
        {
          'text-light-text-200': darkMode === false,
          'text-dark-text-200': darkMode === true,
          'text-light-text-200 dark:text-dark-text-200': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:text-light-text-100': darkMode === false,
          'hover-hover:hover:text-dark-text-100': darkMode === true,
          'hover-hover:hover:text-light-text-100 hover-hover:dark:hover:text-dark-text-100': darkMode === 'auto',
        },
        // Active
        {
          'hover-hover:active:text-canary-500': darkMode === false,
          'hover-hover:active:text-grape-500': darkMode === true,
          'hover-hover:active:text-canary-500 hover-hover:dark:active:text-grape-500': darkMode === 'auto',
        },
        // Focus
        {
          'focus-visible:ring-canary-500': darkMode === false,
          'focus-visible:ring-grape-500': darkMode === true,
          'focus-visible:ring-canary-500 dark:focus-visible:ring-grape-500': darkMode === 'auto',
        },
      ],
      colorScheme === OzPlainButtonColorScheme.TertiaryIcon && [
        // Base
        {
          'text-dark-text-300': darkMode === false,
          'text-light-text-300': darkMode === true,
          'text-dark-text-300 dark:text-light-text-300': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:text-dark-text-200': darkMode === false,
          'hover-hover:hover:text-light-text-200': darkMode === true,
          'hover-hover:hover:text-dark-text-200 hover-hover:dark:hover:text-light-text-200': darkMode === 'auto',
        },
        // Active
        {
          'hover-hover:active:text-grape-500': darkMode === false,
          'hover-hover:active:text-canary-500': darkMode === true,
          'hover-hover:active:text-grape-500 hover-hover:dark:active:text-canary-500': darkMode === 'auto',
        },
      ],
      colorScheme === OzPlainButtonColorScheme.Active && [
        // Base
        {
          'text-grape-500': darkMode === false,
          'text-canary-500': darkMode === true,
          'text-grape-500 dark:text-canary-500': darkMode === 'auto',
        },
      ],
      // Focused
      colorScheme !== OzPlainButtonColorScheme.SecondaryIconInverse && {
        'focus-visible:ring-grape-500': darkMode === false,
        'focus-visible:ring-canary-500': darkMode === true,
        'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500': darkMode === 'auto',
      },
    ]"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    @mousedown="$emit('mousedown', $event)"
    @keydown="$emit('keydown', $event)"
    @click="$emit('click', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @touchstart="$emit('touchstart', $event)"
    @pointerdown="$emit('pointerdown', $event)"
  >
    <!-- @slot Only icon left. -->
    <slot v-if="iconLeft" name="icon"></slot>

    <!-- @slot Text. -->
    <span v-if="text" :class="['flex-1', shouldTruncateText && 'truncate']">{{ text }}</span>

    <!-- @slot Only icon right (default). -->
    <slot v-if="!iconLeft" name="icon"></slot>

    <!-- @slot For customization. -->
    <slot></slot>
  </OzBaseButton>
</template>
